<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Calendário de Transportes</div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row mb-3">
                <div
                  class="col-md-2"
                  style="font-size: 18px; font-weight: bold; margin-top: -5px"
                >
                  Legenda:
                </div>
                <div class="col-md-0">
                  <div class="quad verde">.</div>
                </div>
                <div class="col-md-2">Finalizado</div>
                <div class="col-md-0">
                  <div class="quad azul">.</div>
                </div>
                <div class="col-md-2">Programado</div>
                <div class="col-md-0">
                  <div class="quad amarelo">.</div>
                </div>
                <div class="col-md-3">Aguardando Programação</div>
              </div>
              <section>
                <!-- Content -->
                <div v-if="loadedComponent" class="mr-3 ml-3">
                  <!-- Main Content -->
                  <FullCalendar
                    v-if="loadedComponent"
                    :options="calendarOptions"
                  />
                </div>
                <div v-else class="mt-3 mb-3 centered">
                  Carregando o calendário ...
                </div>
              </section>
              <section>
                <div class="row centered mt-4">
                  <div class="col-md-12">
                    <button
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#modalImpressao"
                    >
                      <i class="fas fa-print"></i> Imprimir Calendário Diário
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Calendario -->
    <div
      class="modal fade"
      id="modalImpressao"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalImpressaoTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Imprimir Agenda Diária
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label>Selecione um dia</label>
                <input type="date" class="form-control" v-model="print.day" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="imprimir"
              :disabled="!print.day"
            >
              <i class="fas fa-print"></i> Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  data() {
    return {
      print: {
        day: "",
      },
      isLoading: true,
      loadedComponent: false,
      actualDate: moment().format("LL"),
      actualMonth: moment().format("MMMM [de] YYYY"),
      calendarOptions: {
        locale: ptLocale,
        eventTimeFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: false,
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "timeGridWeek",
        height: "700px",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        // select: this.handleDateSelect,
        events: [],
        // slotMinTime: "06:00:00",
      },
    };
  },
  computed: {},
  methods: {
    imprimir() {
      const self = this;
      var url = self.$store.state.url + "agendamento/diario/" + self.print.day;
      window.open(url, "_blank").focus();
    },
    bringData: function () {
      const self = this;
      const api = self.$store.state.api + "calendario";

      axios
        .post(api, self.filters)
        .then((response) => {
          self.calendarOptions.events = response.data;

          self.$loading(false);
          self.loadedComponent = true;
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    handleEventClick(clickInfo) {
      var info = this.calendarOptions.events.find(
        (element) => element.id == clickInfo.event.id
      );

      this.$router.push("/programacoes/programacao/" + info.id);
    },
  },
  mounted: function () {
    const self = this;
    self.bringData();
    self.$loading(true);
  },
  components: {
    vSelect,
    FullCalendar,
  },
};
</script>
<style scoped>
.quad {
  height: 15px;
  width: 15px;
}
.verde {
  background-color: #28c76f;
  color: #28c76f;
}
.amarelo {
  background-color: #ff9f43;
  color: #ff9f43;
}
.azul {
  background-color: #15488a;
  color: #15488a;
}
</style>