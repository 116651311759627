<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud :ref="'transportesCrud'" crudName="Solicitações" newText="Nova Solicitação" :table="table"
          :columns="columns" :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true"
          :enableDelete="false" :enableView="false" :enableReset="false" :dataForm="dataForm"
          :customFilters="customFilters" :extra="extra" @view="view" @duplicate="duplicate" @filtered_ds="filtered_ds"
          @filtered_dp="filtered_dp">
        </base-crud>
      </div>
    </div>
    <!-- Modal Duplicar -->
    <div class="modal fade" id="modalDuplicateCenter" tabindex="-1" role="dialog"
      aria-labelledby="modalDuplicateCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDuplicateCenterTitle">
              Duplicar Evento
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <label>Data do Transporte</label>
                <input v-model="duplicate_data.data_procedimento" type="datetime-local" class="form-control" />
              </div>
              <div class="col-md-6">
                <label>Motivo do Transporte</label>
                <v-select :options="motivo_transporte" label="motivo_transporte_nome"
                  v-model="duplicate_data.motivo_transporte_id" item-text="motivo_transporte_nome">
                  <div slot="no-options">
                    Aguarde, carregando lista de Motivos
                  </div>
                </v-select>
              </div>
              <div class="col-md-12 mt-1">
                <label>Observações do Transporte</label>
                <textarea class="form-control" cols="30" rows="3"
                  v-model="duplicate_data.observacao_transporte"></textarea>
              </div>

              <div class="col-md-12 mt-1">
                <div class="b-card-subtitle">
                  Endereço de Origem
                </div>
              </div>


              <div class="col-md-6" @click="getUnidades">
                <label><span class="required">*</span> Tipo de
                  Endereço</label>
                <select class="form-control" v-model="duplicate_data.origem.tipo_estabelecimento_id">
                  <option v-for="tipo in tipos_domicilio" :key="tipo.tipo_domicilio_id" :value="tipo.tipo_domicilio_id">
                    {{ tipo.tipo_domicilio_nome }}
                  </option>
                </select>
              </div>

              <div class="col-md-6">
                <label>Unidade de Origem</label>
                <v-select :options="unidade_saude" label="unidade_saude_nome"
                  v-model="duplicate_data.origem.unidade_saude_id" v-on:input="selectUnidadeOrigem">
                  <div slot="no-options">
                    Aguarde, carregando lista de Unidades
                  </div>
                </v-select>
              </div>
              <div></div>
              <div class="col-md-2">
                <label>CEP da Origem</label>
                <input v-model="duplicate_data.origem.cep" v-on:keyup="buscarOrigem" type="tel" class="form-control"
                  v-mask="'99999-999'" />
              </div>

              <div class="col-md-5">
                <label><span class="required">*</span> Logradouro</label>
                <input v-model="duplicate_data.origem.endereco" type="text" class="form-control" />
              </div>
              <div class="col-md-2">
                <label><span class="required">*</span> Número</label>
                <input id="inputNumero" v-model="duplicate_data.origem.numero" type="text" class="form-control" />
              </div>
              <div class="col-md-3">
                <label>Bairro</label>
                <input v-model="duplicate_data.origem.bairro" type="text" class="form-control" />
              </div>

              <div class="col-md-4">
                <label><span class="required">*</span> Estado</label>
                <v-select :options="ufs" :reduce="(ufs) => ufs.id" :label="'uf_nome'"
                  v-on:input="getEstadoMunicipioOrigem" v-model="duplicate_data.origem.estado_id" class="vselect" />
              </div>
              <div class="col-md-4">
                <label><span class="required">*</span> Município</label>
                <v-select :options="municipios_origem" :reduce="(municipios) => municipios.id" :label="'municipio_nome'"
                  v-model="duplicate_data.origem.municipio_id" class="vselect" />
              </div>
              <div class="col-md-4">
                <label>Complemento</label>
                <input v-model="duplicate_data.origem.complemento" type="text" class="form-control" />
              </div>
              <div class="col-md-8">
                <label>Ponto de Referência</label>
                <input v-model="duplicate_data.origem.ponto_referencia" type="text" class="form-control" />
              </div>
              <div class="col-md-4">
                <label for="Name"> Telefone: </label>
                <input type="text" id="telefone-celular" class="form-control" v-model="duplicate_data.origem.telefone" />
              </div>
              <div class="col-md-4">
                <label>Setor</label>
                <input v-model="duplicate_data.origem.setor" type="text" class="form-control" />
              </div>
              <div class="col-md-4">
                <label>Pavimento</label>
                <input v-model="duplicate_data.origem.pavimento" type="text" class="form-control" />
              </div>
              <div class="col-md-4">
                <label>Leito</label>
                <input v-model="duplicate_data.origem.leito" type="text" class="form-control" />
              </div>


              <div class="col-md-12 mt-1">
                <div class="b-card-subtitle">
                  Endereço de Destino
                </div>
              </div>

              <div class="col-md-6" @click="getUnidades">
                <label><span class="required">*</span> Tipo de
                  Endereço</label>
                <select class="form-control" v-model="duplicate_data.destino.tipo_estabelecimento_id">
                  <option v-for="tipo in tipos_domicilio" :key="tipo.tipo_domicilio_id" :value="tipo.tipo_domicilio_id">
                    {{ tipo.tipo_domicilio_nome }}
                  </option>
                </select>
              </div>

              <div class="col-md-6">
                <label>Unidade de Destino</label>
                <v-select :options="unidade_saude" label="unidade_saude_nome"
                  v-model="duplicate_data.destino.unidade_saude_id" v-on:input="selectUnidadeDestino">
                  <div slot="no-options">
                    Aguarde, carregando lista de Unidades
                  </div>
                </v-select>
              </div>
              <div></div>
              <div class="col-md-2">
                <label>CEP do Destino</label>
                <input v-model="duplicate_data.destino.cep" type="tel" v-on:keyup="buscarDestino" class="form-control"
                  v-mask="'99999-999'" />
              </div>

              <div class="col-md-5">
                <label><span class="required">*</span> Logradouro</label>
                <input v-model="duplicate_data.destino.endereco" type="text" class="form-control" />
              </div>
              <div class="col-md-2">
                <label><span class="required">*</span> Número</label>
                <input id="inputNumeroDestino" v-model="duplicate_data.destino.numero" type="text" class="form-control" />
              </div>
              <div class="col-md-3">
                <label>Bairro</label>
                <input v-model="duplicate_data.destino.bairro" type="text" class="form-control" />
              </div>

              <div class="col-md-4">
                <label><span class="required">*</span> Estado</label>
                <v-select :options="ufs" :reduce="(ufs) => ufs.id" :label="'uf_nome'"
                  v-on:input="getEstadoMunicipioDestino" v-model="duplicate_data.destino.estado_id" class="vselect" />
              </div>
              <div class="col-md-4">
                <label><span class="required">*</span> Município</label>
                <v-select :options="municipios_destino" :reduce="(municipios) => municipios.id" :label="'municipio_nome'"
                  v-model="duplicate_data.destino.municipio_id" class="vselect" />
              </div>
              <div class="col-md-4">
                <label>Complemento</label>
                <input v-model="duplicate_data.destino.complemento" type="text" class="form-control" />
              </div>
              <div class="col-md-8">
                <label>Ponto de Referência</label>
                <input v-model="duplicate_data.destino.ponto_referencia" type="text" class="form-control" />
              </div>
              <div class="col-md-4">
                <label for="Name"> Telefone: </label>
                <input type="text" id="telefone-celular" class="form-control" v-model="duplicate_data.destino.telefone" />
              </div>
              <div class="col-md-4">
                <label>Setor</label>
                <input v-model="duplicate_data.destino.setor" type="text" class="form-control" />
              </div>
              <div class="col-md-4">
                <label>Pavimento</label>
                <input v-model="duplicate_data.destino.pavimento" type="text" class="form-control" />
              </div>
              <div class="col-md-4">
                <label>Leito</label>
                <input v-model="duplicate_data.destino.leito" type="text" class="form-control" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="saveDuplicate">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      duplicate_data: {
        id: null,
        origem: {
          endereco: "",
          bairro: "",
          numero: "",
          cep: "",
          complemento: "",
          municipio_id: "",
          estado_id: "",
          telefone: "",
        },
        destino: {
          endereco: "",
          bairro: "",
          numero: "",
          cep: "",
          complemento: "",
          municipio_id: "",
          estado_id: "",
          telefone: "",
        },
      },
      ufs: [],
      motivo_transporte: [],
      municipios_origem: [],
      municipios_destino: [],
      customFilters: [
        {
          name: "data_solicitacao_f",
          type: "calendar",
          call: "filtered_ds",
        },
        {
          name: "data_procedimento_f",
          type: "calendar",
          call: "filtered_dp",
        },
      ],
      unidade_saude: [],
      tipos_domicilio: [],
      data_solicitacao: "",
      table: "solicitacoesTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: [
        "numero_solicitacao",
        "data_solicitacao_f",
        "data_procedimento_f",
        "tipo_transporte_id",
        "paciente_id",
        "status_solicitacao",
        "actions",
      ],
      tableData: [
        "numero_solicitacao",
        "data_solicitacao",
        "data_procedimento",
        "unidade_saude_id",
        "tipo_transporte_id",
        "paciente_id",
        "status_solicitacao",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        sortable: [
          "numero_solicitacao",
          "data_solicitacao",
          "data_procedimento",
          "tipo_transporte_id",
          "paciente_id",
          "status_solicitacao",
        ],
        filterable: [
          "numero_solicitacao",
          "paciente_id",
          "tipo_transporte_id",
          "status_solicitacao",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          numero_solicitacao: "Número Solicitação",
          data_solicitacao_f: "Data da Solicitação",
          data_procedimento_f: "Data do Transporte",
          unidade_saude_id: "Unidade solicitante",
          tipo_transporte_id: "Tipo de transporte",
          paciente_id: "Nome Paciente",
          status_solicitacao: "Status",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status_solicitacao: [
            {
              id: 0,
              text: "Solicitado",
            },
            {
              id: 1,
              text: "Aprovado",
            },
            {
              id: 2,
              text: "Programado",
            },
            {
              id: 3,
              text: "Finalizado",
            },
            {
              id: 4,
              text: "Cancelado",
            },
          ],
        },
        templates: {
          tipo_transporte_id: function (h, row, index) {
            return row.tipo_transporte.tipo_transporte_nome;
          },
          paciente_id: function (h, row, index) {
            return row.paciente ? row.paciente.nome_paciente : "NÃO SE APLICA";
          },

          // profissional_id: function (h, row, index) {
          //   return row.profissional.profissional_nome;
          // },
          status_solicitacao: function (h, row, index) {
            if (row.status_solicitacao == 0) {
              return (
                <h5>
                  <span class="badge badge-warning">Solicitado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 1) {
              return (
                <h5>
                  <span class="badge badge-info">Aprovado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 2) {
              return (
                <h5>
                  <span class="badge badge-primary">Programado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 3) {
              return (
                <h5>
                  <span class="badge badge-success">Finalizado</span>
                </h5>
              );
            }
            if (row.status_solicitacao == 4) {
              return (
                <h5>
                  <span class="badge badge-danger">
                    Cancelado
                    <p class="mt-1">
                      Motivo:{" "}
                      {row.cancelamento
                        ? row.cancelamento.cancelamento_nome
                        : "Não informado"}
                    </p>
                  </span>
                </h5>
              );
            }
          },
          // cancelamento_id: function (h, row, index) {
          //   return row.cancelamento.cancelamento_nome;
          // },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.data_procedimento != null &&
            this.$parent.$parent.$parent.data_procedimento != ""
          ) {
            requestData.params.data_procedimento =
              this.$parent.$parent.$parent.data_procedimento;
          }

          if (
            this.$parent.$parent.$parent.data_solicitacao != null &&
            this.$parent.$parent.$parent.data_solicitacao != ""
          ) {
            requestData.params.data_solicitacao =
              this.$parent.$parent.$parent.data_solicitacao;
          }

          requestData.params.query = "";
          requestData.params.with = [
            "paciente",
            // "profissional",
            "tipo_transporte",
            "solicitante",
            "cancelamento",
          ];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "ml-1 btn btn-crud view",
          title: "Visualizar",
          name: "view",
          icon: "fas fa-eye",
        },
        {
          id: 2,
          label: "",
          class: "ml-1 btn btn-crud check mt-1",
          title: "Duplicar",
          name: "duplicate",
          icon: "fas fa-clone",
        },
      ],
      endPoint: "solicitacoes",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_dp(date) {
      this.data_procedimento = date;
    },
    filtered_ds(date) {
      this.data_solicitacao = date;
    },
    view(props, row, index) {
      const self = this;
      this.$router.push("/aprovacoes/aprovacao/" + props.row.id);
    },
    duplicate(props, row, index) {
      const self = this;

      console.log(props);

      self.duplicate_data.id = props.row.transporte_sanitario_id;

      $("#modalDuplicateCenter").modal("show");
    },
    saveDuplicate() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/duplicate";

      axios
        .post(api, self.duplicate_data)
        .then((response) => {
          $("#modalDuplicateCenter").modal("hide");
          self.$message("Sucesso", "Solicitação Duplicada", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },

    getUnidades: function () {
      const self = this;
      let api =
        self.$store.state.api +
        "unidade_saude?paginated=false&orderByAsc=unidade_saude_nome";

      axios
        .get(api)
        .then((response) => {
          self.unidade_saude = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getEstadoMunicipioOrigem() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.duplicate_data.origem.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_origem = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getEstadoMunicipioDestino() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.duplicate_data.destino.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_destino = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");

        });
    },

    getTiposDomicilio: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_domicilio";

      axios
        .get(api)
        .then((response) => {
          self.tipos_domicilio = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    buscarOrigem: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.duplicate_data.origem.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.duplicate_data.origem.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }

            self.duplicate_data.origem.endereco = endereco.logradouro;
            self.duplicate_data.origem.bairro = endereco.bairro;
            self.duplicate_data.origem.complemento = endereco.complemento;

            let api =
              self.$store.state.api +
              "municipios?municipio_codigo=" +
              endereco.ibge.substring(0, endereco.ibge.length - 1);

            axios
              .get(api)
              .then((response) => {
                self.duplicate_data.origem.estado_id = response.data.data[0].uf_id;
                self.getEstadoMunicipioOrigem();
                self.duplicate_data.origem.municipio_id =
                  response.data.data[0].municipio_id;
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            $("#inputNumero").focus();
          }
        );
      }
    },

    buscarDestino: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.duplicate_data.destino.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.duplicate_data.destino.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumeroDestino").focus();
              self.naoLocalizado = true;
              return;
            }

            self.duplicate_data.destino.endereco = endereco.logradouro;
            self.duplicate_data.destino.bairro = endereco.bairro;
            self.duplicate_data.destino.complemento = endereco.complemento;

            let api =
              self.$store.state.api +
              "municipios?municipio_codigo=" +
              endereco.ibge.substring(0, endereco.ibge.length - 1);

            axios
              .get(api)
              .then((response) => {
                self.duplicate_data.destino.estado_id = response.data.data[0].uf_id;
                self.getEstadoMunicipioDestino();
                self.duplicate_data.destino.municipio_id =
                  response.data.data[0].municipio_id;
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            $("#inputNumeroDestino").focus();
          }
        );
      }
    },

    getMotivos() {
      const self = this;
      let api = self.$store.state.api + "get_motivos_por_tipo";

      axios
        .post(api, { tipo_transporte: 1 })
        .then((response) => {
          self.motivo_transporte = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getEstadoMunicipioOrigem() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.duplicate_data.origem.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_origem = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioDestino() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.duplicate_data.destino.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_destino = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    selectUnidadeOrigem() {
      const self = this;

      console.log("municipio: ", self.duplicate_data.origem.municipio_id.trim() == "");

      self.duplicate_data.origem.cep = self.duplicate_data.origem.unidade_saude_id.cep;
      self.duplicate_data.origem.endereco =
        self.duplicate_data.origem.unidade_saude_id.endereco;
      self.duplicate_data.origem.numero = self.duplicate_data.origem.unidade_saude_id.numero;
      self.duplicate_data.origem.ponto_refencia =
        self.duplicate_data.origem.unidade_saude_id.ponto_refencia;
      self.duplicate_data.origem.complemento =
        self.duplicate_data.origem.unidade_saude_id.complemento;
      self.duplicate_data.origem.bairro = self.duplicate_data.origem.unidade_saude_id.bairro;
      self.duplicate_data.origem.municipio_id =
        self.duplicate_data.origem.unidade_saude_id.municipio_id;
      self.duplicate_data.origem.estabelecimento_nome =
        self.duplicate_data.origem.unidade_saude_id.unidade_saude_nome;

      let api =
        self.$store.state.api +
        "municipios?municipio_id=" +
        self.duplicate_data.origem.municipio_id;

      axios
        .get(api)
        .then((response) => {
          self.duplicate_data.origem.estado_id = response.data.data[0].uf_id;
          self.getEstadoMunicipioOrigem();
          self.duplicate_data.origem.municipio_id = response.data.data[0].municipio_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    selectUnidadeDestino() {
      const self = this;

      self.duplicate_data.destino.cep = self.duplicate_data.destino.unidade_saude_id.cep;
      self.duplicate_data.destino.endereco =
        self.duplicate_data.destino.unidade_saude_id.endereco;
      self.duplicate_data.destino.numero = self.duplicate_data.destino.unidade_saude_id.numero;
      self.duplicate_data.destino.ponto_refencia =
        self.duplicate_data.destino.unidade_saude_id.ponto_refencia;
      // self.duplicate_data.destino.complemento =
      //   self.duplicate_data.destino.unidade_saude_id.complemento;
      self.duplicate_data.destino.bairro = self.duplicate_data.destino.unidade_saude_id.bairro;
      self.duplicate_data.destino.estabelecimento_nome =
        self.duplicate_data.destino.unidade_saude_id.unidade_saude_nome;

      let api =
        self.$store.state.api +
        "municipios?municipio_id=" +
        self.duplicate_data.destino.unidade_saude_id.municipio_id;

      axios
        .get(api)
        .then((response) => {
          self.duplicate_data.destino.estado_id = response.data.data[0].uf_id;
          self.getEstadoMunicipioDestino();
          self.duplicate_data.destino.municipio_id = response.data.data[0].municipio_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    query: function (query) {
      let columns = {
        numero_solicitacao: "numero_solicitacao",
        data_solicitacao: "data_solicitacao",
        tipo_transporte_id: "tipo_transporte[tipo_transporte_nome]",
        paciente_id: "paciente[nome_paciente]",
        status_solicitacao: "status_solicitacao",
        cancelamento_id: "cancelamento[cancelamento_nome]",
      };
      let filters = "orderByDesc=data_solicitacao";
      $.each(query, function (index, value, row) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
  mounted() {
    this.getMotivos();
    this.getTiposDomicilio();
    this.getUFs();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>